define(['app'], (app) => ({
  getChoices = () => [],
  initialValue = () => '',
  renderDropdownList,
}) => {
  // % in javascript is remainder, -1 % 5 would return -1, but i want -1 mod 5, i.e. 4
  const mod = (x, y) => ((x % y) + y) % y;
  const isElementInViewY = (element, parent) => {
    return parent.scrollTop <= element.offsetTop &&
      (element.offsetTop + element.offsetHeight) <= (parent.scrollTop + parent.offsetHeight);
  };

  const scrollIntoViewOf = (
    element,
    scrollableParent = document.body,
    { alignBottom = false } = {}
  ) => {
    if (alignBottom) {
      scrollableParent.scrollTop = element.offsetTop + element.offsetHeight - scrollableParent.offsetHeight;
    } else {
      scrollableParent.scrollTop = element.offsetTop;
    }
  };

  const dropdownInput = () => {
    const comp = {};

    const _selectors = {
      inputDiv: '.dropdownInput',
    }

    const _classes = {
      input: 'dropdownInput_input',
      actual: 'dropdownInput_actual',
      dropdownContainer: 'dropdownInput_dropdown_container',
      dropdown: 'dropdownInput_dropdown',
      dropdownItem: 'dropdownInput_dropdown_item',
    };

    const _attributes = {
      dataClearOnClick: 'data-clear-on-click'
    }

    const _channels = {
      inputSelected: 'dropdownInput/inputSelected',
      refreshChoices: 'dropdownInput/refreshChoices'
    };

    comp.init = (element) => {
      comp.element = element;
      comp.clearOnClick = comp.element.querySelector(_selectors.inputDiv).hasAttribute(_attributes.dataClearOnClick);

      // select all children from _classes
      comp.children = {};
      for (const [key, cls] of Object.entries(_classes)) {
        comp.children[key] = comp.element.querySelector(`.${cls}`);
      }

      comp.highlighted = null;

      comp._addListeners();
      comp._initialValue();
      comp.subscribe();
      comp._getChoices().then(choices => {
          comp._updateActual(choices);
          comp._renderDropdownListToDOMAndRebind(choices);
      });
      return comp;
    };

    comp.subscribe = () => {
      app.subscribe(_channels.refreshChoices, comp.refreshChoices)
    };

    comp.refreshChoices = (dropdown) => {
      if (dropdown === comp.children.actual.name) {
        comp._getChoices().then(choices => {
          comp._updateActual(choices, true);
          comp._renderDropdownListToDOMAndRebind(choices);
        });
      }
    };

    comp.clearDropdown = () => {
      comp.children.input.value = "";
      comp._onInput();
    }
    comp.showDropdown = () => comp.children.dropdownContainer.setAttribute('data-showed', '');
    comp.hideDropdown = () => comp.children.dropdownContainer.removeAttribute('data-showed');

    // note: force getChoices to return a promise to allow async getChoices,
    //       e.g. useful if getChoices needs to send ajax requests
    comp._getChoices = () => Promise.resolve(getChoices(comp.children.input.value, comp));

    comp._initialValue = () => comp.children.input.value = initialValue(comp);

    comp._getChoiceListItems = () => comp.children.dropdown.querySelectorAll(`.${_classes.dropdownItem}-choice`);

    comp._addListeners = () => {
      comp.children.input.addEventListener('input', comp._onInput);
      if(comp.clearOnClick) {
        comp.children.input.addEventListener('click', comp.clearDropdown);
      } else {
        comp.children.input.addEventListener('click', comp.showDropdown);
      }
      comp.children.input.addEventListener('focus', comp.showDropdown);
      comp.children.input.addEventListener('blur', comp.hideDropdown);
      comp.children.input.addEventListener('keydown', comp._onKeydown);
    };

    comp._onInput = () => {
      comp._getChoices().then(choices => {
        comp._updateActual(choices);
        comp._renderDropdownListToDOMAndRebind(choices);
      });
      comp.showDropdown();
      comp.element.setAttribute('aria-expanded', true)
    };

    comp._updateActual = (choices, fromRefresh) => {
      const choice = choices.find(choice => {
        return choice.display !== undefined && comp.children.input.value.toLowerCase() === choice.display.toLowerCase();
      }) || {};
      comp.children.actual.value = choice.value || '';

      if (!fromRefresh) {
        app.publish(_channels.inputSelected, comp.children.actual);
      }
    };

    comp._onKeydown = (event) => {
      switch(event.key) {
        case 'ArrowUp':
          return comp._highlightPrev();
        case 'ArrowDown':
          return comp._highlightNext();
        case 'Enter':
          event.preventDefault();
          return comp._select(comp.highlighted);
        case 'Escape':
          return comp.handleEscapeKeydown(event);
      }
    };

    comp.handleEscapeKeydown = (event) => {
      if(comp.children.dropdownContainer.hasAttribute('data-showed')) {
        event.stopPropagation();
        comp.hideDropdown();
      }
    }

    comp._addListenersForChoices = () => {
      Array.from(comp._getChoiceListItems()).forEach((choiceLi, i) => {
        choiceLi.addEventListener('mousedown', e => e.preventDefault()); // prevents input's blur from firing
        choiceLi.addEventListener('click', e => {
          e.stopPropagation();
          comp._select(i)
        });
      });
    };

    comp._renderDropdownListToDOMAndRebind = choices => {
      comp.children.dropdown.innerHTML = comp._renderDropdownList(choices);
      comp._highlight(null);
      comp._addListenersForChoices();
    };

    comp._renderDropdownList = renderDropdownList || (choices => {
      const inputName = comp.children.actual.getAttribute('name');
      const renderChoiceListItem = ({ type, display }, index) => {
        return `<li id="${inputName}-item-${index}" role="option" aria-selected="false" class="${_classes.dropdownItem} ${_classes.dropdownItem}-${type}">${display}<div class="item-choice-selected"></div></li>`;
      };
      return `<ul id="${inputName}-listbox" role="listbox" aria-labelledby="${inputName}-label">${choices.map(renderChoiceListItem).join('')}</ul>`;
    });

    comp._highlight = i => {
      const choiceLis = comp._getChoiceListItems();

      if (comp.highlighted in choiceLis) {
        choiceLis[comp.highlighted].removeAttribute('data-highlighted');
      }

      if (i !== null) {
        choiceLis[i].setAttribute('data-highlighted', '');
        comp.children.input.setAttribute("aria-activedescendant", choiceLis[i].id)
      }

      comp.highlighted = i;
    };

    comp._highlightPrev = () => {
      const choicesCount = comp._getChoiceListItems().length
      const prevIndex = comp.highlighted === null? choicesCount - 1: mod(comp.highlighted - 1, choicesCount);
      comp._highlight(prevIndex);
      const prevChoice = comp._getChoiceListItems()[prevIndex];
      if (!isElementInViewY(prevChoice, comp.children.dropdown)) {
        scrollIntoViewOf(prevChoice, comp.children.dropdown);
      }
    }

    comp._highlightNext = () => {
      const nextIndex = comp.highlighted === null? 0: mod(comp.highlighted + 1, comp._getChoiceListItems().length);
      comp._highlight(nextIndex);
      const nextChoice = comp._getChoiceListItems()[nextIndex];
      if (!isElementInViewY(nextChoice, comp.children.dropdown)) {
        scrollIntoViewOf(nextChoice, comp.children.dropdown, { alignBottom: true });
      }
    }

    comp._select = i => {
      if (i === null) return;
      const choiceLis = comp._getChoiceListItems();
      comp.children.input.value = choiceLis[i].innerText;
      comp._onInput();
      comp.hideDropdown();
      comp.children.input.removeAttribute("aria-activedescendant");
      comp.element.setAttribute("aria-expanded", false)
    };

    return comp;
  };

  return dropdownInput;
});
